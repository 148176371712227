<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Commodity/ProductList"
          >
            商品列表 /</router-link
          >
          <span class="crumbs_item crumbs_last">
            {{ operations != 1 ? "编辑商品" : "新建商品" }}</span
          >
        </span>
      </div>
    </div>
    <!-- 功能区 -->
    <div class="content_box">
      <a-row>
        <a-col :span="10" class="book_flex">
          <span class="left_name required">商品名称：</span>
          <a-input
            disabled
            v-model="fromOBJ.name"
            placeholder="请输入商品名称"
          />
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="10" class="book_flex">
          <span class="left_name required">机构端单价：</span>
          <a-input-number
            class="right"
            id="inputNumber"
            placeholder="请输入优惠价格"
            v-model="fromOBJ.orgPrice"
            :min="0.01"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="14" class="book_flex">
          <span class="left_name required">商品类别：</span>
          <p>一级：</p>
          <a-select
            style="width: 120px"
            v-model="fromOBJ.category"
            @change="hChange"
          >
            <a-select-option
              v-for="item in goodsClass"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <p v-show="gCchildren.length">-> 二级：</p>
          <a-select
            v-show="gCchildren.length"
            style="width: 120px"
            v-model="fromOBJ.subCategory"
          >
            <a-select-option
              v-for="item in gCchildren"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row v-if="fromOBJ.productType != 1">
        <a-col :span="8" class="book_flex">
          <span class="left_name required">运费设置：</span>
          <!-- <a-radio-group v-model="fromOBJ.freightSet">
            <a-radio :style="radioStyle" :value="1"> 包邮 </a-radio>
            <a-radio :style="radioStyle2" :value="2"> 运费模版 </a-radio>
          </a-radio-group> -->
          <a-select
            placeholder="请选择运费模板"
            v-model="fromOBJ.freightId"
            class="right"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option
              v-for="(item, index) in templateFreight"
              :key="index + 'tp'"
              :value="item.freightId"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="10" class="book_flex left_name_price">
          <span class="left_name required">价格设置：</span>
          <div class="right">
            <a-radio-group v-model="fromOBJ.priceType">
              <a-radio :style="radioStyle" :value="1">
                仅可按机构端单价购买
              </a-radio>
              <a-radio :style="radioStyle2" :value="2">
                满足条件可按机构门槛价格购买
              </a-radio>
              <span
                v-if="fromOBJ.priceType == 2"
                @click.self="addCondition"
                class="addCondition"
                >增加购买门槛</span
              >
            </a-radio-group>
            <ul class="priceJson" v-if="fromOBJ.priceType == 2">
              <li v-for="(item, index) in fromOBJ.priceJson" :key="index">
                <p>购买数量满足</p>
                <a-input-number
                  class="right"
                  id="inputNumber"
                  placeholder=""
                  v-model="item.startNumber"
                  :min="2"
                />
                <p>份时，享受价格</p>
                <a-input-number
                  class="right"
                  id="inputNumber"
                  placeholder=""
                  v-model="item.favorPrice"
                  :min="0.01"
                />
                <p>元</p>
                <p
                  class="delete"
                  @click="fromOBJ.priceJson.splice(index, 1)"
                  v-if="index"
                >
                  删除
                </p>
              </li>
            </ul>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="10" class="book_flex left_name_price">
          <span class="left_name required">是否关联商品：</span>
          <div class="right">
            <a-radio-group v-model="fromOBJ.relevancyProduct">
              <a-radio :value="1"> 是 </a-radio>
              <a-radio :value="0"> 否 </a-radio>
              <span
                v-show="fromOBJ.relevancyProduct == 1"
                @click="categoryFilling()"
                class="addCondition"
                >增加关联商品</span
              >
            </a-radio-group>
            <div class="" v-show="fromOBJ.relevancyProduct == 1">
              <a-table
                :columns="columns"
                :rowSelection="null"
                :data-source="asswdata"
              >
                <template slot="index" slot-scope="item, row, index">
                  {{ index + 1 }}
                </template>
                <template slot="delet" slot-scope="item, row, index">
                  <a-button @click="delFilling(row, index)" type="link">
                    删除关联商品
                  </a-button>
                </template>
              </a-table>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row style="margin-bottom: 0">
        <a-col :span="3">
          <p class="left_name required">购买须知：</p>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="2"></a-col>
        <a-col :span="12" style="margin-top: -22px">
          <UE
            editorId="introduceEditor2"
            :initContent="fromOBJ.buyNotes"
            @input="getContent1"
          />
        </a-col>
      </a-row>
      <div class="series_box">
        <div class="head">
          <span class="required">上架时间</span>
        </div>
        <div class="right-box">
          <a-radio-group v-model="fromOBJ.publishFlag">
            <a-radio :value="1">立即上架</a-radio>
            <a-radio :value="0">暂不上架</a-radio>
          </a-radio-group>
        </div>
      </div>
    </div>

    <div class="button">
      <a-button
        type="primary"
        class="btn"
        :loading="formloading"
        @click="pushData()"
        >确认</a-button
      >
      <a-button class="all_boder_btn btn" @click="$router.go(-1)"
        >取消</a-button
      >
    </div>

    <!-- 商品弹窗 -->
    <a-modal
      v-model="category"
      width="688px"
      title="选择商品"
      :centered="true"
      :closable="false"
    >
      <div class="search-box">
        <a-select
          class="search-input"
          placeholder="请选择类型"
          v-model="categorytype"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option
            v-for="item in goodsClass"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-input
          class="search-input"
          placeholder="请输入名称"
          v-model="categoryname"
          allowClear
        />
        <a-button class="search-btn" type="primary" @click="categoryFilling()"
          >搜索</a-button
        >
      </div>

      <div class="table-box">
        <a-table
          class="table-template"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :rowKey="(item) => item.id"
          :columns="categorycolumns"
          :data-source="categoryData"
          :pagination="{
            total: ctotal,
            current: cpageNumber,
            pageSize: cpageSize,
            showTotal: (res) => `共${ctotal}条`,
          }"
          bordered
          @change="oncPage"
        >
          <template slot="index" slot-scope="item, row, index">
            {{ (cpageNumber - 1) * cpageSize + index + 1 }}
          </template>

          <template slot="type" slot-scope="item">
            <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
            <span v-if="item == 1">课程</span>
            <span v-else-if="item == 2">图书</span>
            <span v-else-if="item == 3">教具</span>
            <span v-else-if="item == 4">模拟考试</span>
            <span v-else-if="item == 5">电子照片</span>
            <span v-else-if="item == 6">延期</span>
            <span v-else-if="item == 7">补考</span>
            <span v-else-if="item == 8">直播</span>
            <span v-else-if="item == 9">其他</span>
            <span v-else>-</span>
          </template>
        </a-table>
      </div>

      <template slot="footer">
        <a-button type="primary" @click="categoryOk">确认</a-button>
        <a-button @click="category = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
// import { TreeSelect } from "ant-design-vue";
import UE from "@/components/UE.vue";
import moment from "moment";
const categorycolumns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
  },
];
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "delet",
    scopedSlots: { customRender: "delet" },
  },
];
export default {
  // 可用组件的哈希表
  components: {
    UE,
  },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      categorycolumns, // 新建商品的表头
      columns, // 关联商品的表头
      category: false, // 	外面展示
      asswdata: [], // 关联商品数据
      selectedRowKeys: [], // 选择的数据
      formloading: false,
      loading: false,
      imageUrl: undefined,
      ueInitText1: "",
      buyNotes: "",
      selectVisible: false, // 选择商品弹窗
      relation: false,
      ptotal: 0,
      ppageNumber: 1, //页码
      ppageSize: 10, // 页数
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      value: "",
      // 偏好
      preference: [],
      preferenceId: [],
      // 商品分类
      goodsClass: [], //一级
      gCchildren: [], //二级
      // 运费模板
      templateFreight: [],
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      radioStyle2: {
        display: "inline-block",
        height: "30px",
        lineHeight: "30px",
      },
      // 需要传输的数据
      fromOBJ: {
        priceJson: [
          {
            startNumber: "", // 满足数量
            type: 1,
            favorPrice: "", // 优惠价格
          },
        ],
        priceType: "", //优惠价类型 1、机构优惠价 2、购买阶梯价
        name: "", //	商品名称
        buyNotes: null, //购买须知
        id: null, //	机构商品id
        productId: null, //	商品id
        // freightSet: null, //		运费设置1.包邮2.运费模版
        freightId: undefined, //	关联运费模版
        introduction: null, //	简介
        category: null, // 	商品一级分类id，数据字典表配置ctotalcategory
        subCategory: null, // 商品二级分类id 数据字典表配置
        // photoList: [],
        productType: null, // 	商品属性分类

        relevancyProduct: 0, // 是否关联商品
      },

      // 关联的商品 详情图片信息
      // photoList: [],
      // 替换字段
      replaceFields: {
        title: "name",
        value: "id",
        key: "id",
      },

      //商品弹窗
      popup: "",
      categoryname: "",
      categorytype: undefined,
      categoryData: [],
      ctotal: 0,
      cpageNumber: 1, //页码
      cpageSize: 5, // 页数
      // 列表数据
      selectData: [],

      spageNumber: 1, //页码
      spageSize: 5, // 页数
      //商品类别
      goodList: [
        { id: 1, classify: "课程" },
        { id: 2, classify: "图书" },
        { id: 3, classify: "教具" },
        { id: 4, classify: "模拟考试" },
        { id: 5, classify: "电子照片" },
        { id: 6, classify: "延期" },
        { id: 7, classify: "补考" },
        { id: 8, classify: "直播" },
      ],
      cgoodList: [
        // { id: 0, classify: "全部" },
        { id: 1, classify: "课程" },
        { id: 2, classify: "图书" },
        { id: 3, classify: "教具" },
        { id: 4, classify: "模拟考试" },
        // { id: 5, classify: "电子照片" },
        // { id: 6, classify: "延期" },
        // { id: 7, classify: "补考" },
        // { id: 8, classify: "直播" },
      ],
      // 保存的标志位
      dataFlag: true,
      loadingMobile: false,
      loadinghomePage: false,
      loading2: false,
      loading3: false,
      file: {},
      pcfile: {},
      operations: 0,
      id: null, // 查询详情id
    };
  },
  // 事件处理器
  methods: {
    // 选择器
    onSelectChange(selectedRowKeys) {
      // console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    // 商品弹窗
    oncPage(e) {
      this.cpageNumber = e.current;
      this.cpageSize = e.pageSize;
      this.categoryFilling();
    },
    // 获取全部商品
    categoryFilling() {
      this.category = true;
      this.$ajax({
        url: "/hxclass-management/organiz-product/list",
        params: {
          productName: this.categoryname,
          category: this.categorytype == 0 ? null : this.categorytype,
          pageNum: this.cpageNumber,
          pageSize: this.cpageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.ctotal = res.data.total;
          this.categoryData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //新建关联商品
    categoryOk() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择商品");
        return;
      }
      // 不可以关联自己，
      if (this.selectedRowKeys.indexOf(Number(this.id)) != -1) {
        this.$message.warning("不可以关联自己");
        return;
      }
      // 不可以关联同一个商品两次
      for (let index = 0; index < this.asswdata.length; index++) {
        if (this.selectedRowKeys.indexOf(Number(this.asswdata[index].relevancyOrgProductId) ) != -1)
         {
          this.$message.warning("不可以关联同一个商品两次");
          return;
        }
      }
      // 数据解析
      let arr = [];
      this.selectedRowKeys.forEach((element) => {
        arr.push({
          orgProductId: this.id,
          relevancyOrgProductId: element,
        });
      });
      // 请求关联商品
      this.$ajax({
        url: "/hxclass-management/organiz-product/save/relevancy",
        method: "put",
        params: arr,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          // 回显数据，需要查接口回显，因为现在这里不能获取到全部数据
          this.category = false;
          this.editData();
          this.selectedRowKeys=[];
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 删除关联商品
    delFilling(e, r) {
      this.$ajax({
        url:
          "/hxclass-management/organiz-product/delete/relevancy?id=" +
          e.id,
        method: "DELETE",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.asswdata.splice(r, 1);
          console.log(this.asswdata);
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 赋值二级分类
    hChange(value) {
      this.fromOBJ.subCategory = null;
      console.log(`selected ${value}`);
      this.goodsClass.forEach((element) => {
        if (element.id == value) {
          this.gCchildren = element.children;
        }
      });
    },
    // 时间
    moment,
    // 添加购买门槛条件
    addCondition() {
      this.fromOBJ.priceJson.push({
        startNumber: "",
        favorPrice: "",
        type: 1,
      });
    },
    // 富文本内容有变化时触发
    getContent1(e) {
      this.$set(this.fromOBJ, "buyNotes", e);
    },
   

    // 获取机构端商品分类
    getgoods(e) {
      this.$ajax({
        url: "/hxclass-management/organiz-product-categroy/tree",
        method: "get",
        params: {
          type: 1,
          pid: 0,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.goodsClass = res.data;
          // 为1是新建详情，2是编辑详情，接口不同
          if (this.operations == 1) {
            this.getnewDetail();
          } else {
            this.getDetail();
          }
        }
      });
    },
    // 获取新建时商品详情
    getnewDetail() {
      this.$ajax({
        url: "/hxclass-management/product-archives/manage/select",
        params: {
          id: this.id,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.fromOBJ.name = res.data.name; //商品名字
          this.fromOBJ.productId = res.data.productId; //商品productId
          this.fromOBJ.introduction = res.data.abstracts; //商品productId
          this.fromOBJ.productType = res.data.type; //商品名字
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取编辑时商品详情
    getDetail() {
      this.$ajax({
        url: "/hxclass-management/organiz-product/get?id=" + this.id,
        params: {
          id: this.id,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          for (const key in res.data) {
            this.fromOBJ[key] = res.data[key];
          }
          // 获取二级分类
          this.goodsClass.forEach((element) => {
            if (element.id == res.data.category) {
              this.gCchildren = element.children;
            }
          });
          this.fromOBJ.name = res.data.productName;
          this.fromOBJ.priceJson = res.data.priceList;
          this.getContent1(res.data.buyNotes);
          // 获取关联商品
          if (res.data.relevancyProducts) {
            this.asswdata = res.data.relevancyProducts;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 获取运费模板
    getFreight() {
      this.$ajax({
        url: "/hxclass-management/product-freight/manage/select-list",
        method: "get",
        params: {
          // page: 1,
          // size: 99999,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.templateFreight = res.data;
        }
      });
    },


     // 提交/编辑
    pushData() {
      if (this.formloading) return;
      this.formloading = true;
      let obj = {};
      for (const key in this.fromOBJ) {
        obj[key] = this.fromOBJ[key];
      }
      if (!obj.name) {
        return this.$message.error("请输入商品名称");
      } else if (!obj.category) {
        return this.$message.error("请选择商品一级分类");
      } else if (!obj.subCategory && this.gCchildren.length > 0) {
        // 判断二级分类id，必须是有可以选的id才判断
        return this.$message.error("请选择商品二级分类");
        // } else if (!obj.freightSet) {
        //   return this.$message.error("请选择运费方式");&& this.freightSet == 2
      } else if (!obj.freightId && obj.productType != 1) {
        return this.$message.error("请选择运费模版");
      } else if (obj.publishFlag === null) {
        return this.$message.error("请选择是否上架");
      } else if (obj.orgPrice === null) {
        return this.$message.error("请输入优惠价格");
      } else if (!obj.priceType) {
        return this.$message.error("请选择价格设置");
      } else if (!obj.priceType == 2) {
        // 如果选择了阶梯价。则判断阶梯价是否正常填写
        obj.priceJson.forEach((element) => {
          if (!element.startNumber || element.favorPrice) {
            return this.$message.error("请选择价格设置");
          }
        });
      }
      // 如果选择了阶梯价才给传值否则不传任何值
      if (obj.priceType == 1) {
        obj.priceJson = null;
      } else {
        obj.priceJson = JSON.stringify(obj.priceJson);
      }
      let urls = "";
      let method = "";
      // 只有1的时候是新建
      if (this.operations != 1) {
        urls = "/hxclass-management/organiz-product/edit";
        method = "put";
      } else {
        urls = "/hxclass-management/organiz-product/save";
        method = "post";
      }
      this.$ajax({
        url: urls,
        method: method,
        params: obj,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败");
          // this.$router.go(-1);
        }
        this.formloading = false;
      });
    },

     // 关联课程的时候保存/编辑
    editData() {
      if (this.formloading) return;
      this.formloading = true;
      let obj = {};
      for (const key in this.fromOBJ) {
        obj[key] = this.fromOBJ[key];
      }
      if (!obj.name) {
        return this.$message.error("请输入商品名称");
      } else if (!obj.category) {
        return this.$message.error("请选择商品一级分类");
      } else if (!obj.subCategory && this.gCchildren.length > 0) {
        // 判断二级分类id，必须是有可以选的id才判断
        return this.$message.error("请选择商品二级分类");
        // } else if (!obj.freightSet) {
        //   return this.$message.error("请选择运费方式");&& this.freightSet == 2
      } else if (!obj.freightId && obj.productType != 1) {
        return this.$message.error("请选择运费模版");
      } else if (obj.publishFlag === null) {
        return this.$message.error("请选择是否上架");
      } else if (obj.orgPrice === null) {
        return this.$message.error("请输入优惠价格");
      } else if (!obj.priceType) {
        return this.$message.error("请选择价格设置");
      } else if (!obj.priceType == 2) {
        // 如果选择了阶梯价。则判断阶梯价是否正常填写
        obj.priceJson.forEach((element) => {
          if (!element.startNumber || element.favorPrice) {
            return this.$message.error("请选择价格设置");
          }
        });
      }
      // 如果选择了阶梯价才给传值否则不传任何值
      if (obj.priceType == 1) {
        obj.priceJson = null;
      } else {
        obj.priceJson = JSON.stringify(obj.priceJson);
      }
      
      this.$ajax({
        url: "/hxclass-management/organiz-product/edit",
        method: "put",
        params: obj,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.getDetail();
        } else {
          this.$message.error("操作失败");
          // this.$router.go(-1);
        }
        this.formloading = false;
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.operations = this.$route.query.operation;
    this.id = this.$route.query.id;
    this.getgoods();
    this.getFreight();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
a {
  color: #333333;
}
.redtext {
  font-size: 14px;
  color: red;
}

.greytext {
  font-size: 14px;
  color: #888;
}
.pictures {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    .icon {
      opacity: 1;
    }
  }
  .icon {
    transition: all 0.3s;
    opacity: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    i {
      color: #fff;
      margin: 0 5px;
      font-size: 18px;
    }
  }
}
.content_box {
  padding: 20px 0;
  .big_title {
    font-size: 18px;
    color: #333333;
    font-weight: 500;
    margin: 30px 0;
  }
}
.left_name {
  display: inline-block;
  font-weight: 500;
  line-height: 26px;
  color: #333333;
}
// 必填标识
.required::before {
  content: "*";
  display: inline-block;
  color: red;
  position: relative;
  top: 2px;
  margin-right: 2px;
}
.ant-row {
  margin-bottom: 20px;
}
.ant-col-2 {
  text-align: right;
  margin-right: 10px;
}
.flex_Div {
  display: flex;
  align-items: center;
  .flex_left_Div {
    text-align: center;
    .size {
      color: red;
      font-size: 14px;
    }
  }
}

// 书籍信息
.book_flex {
  display: flex;
  align-items: center;
  > span {
    display: inline-block;
    text-align: right;
    min-width: 142px;
  }
  .right {
    width: 100%;
    .addCondition {
      display: inline-block;
      color: #3681f0;
      margin-left: 20px;
      cursor: pointer;
    }
    .priceJson {
      margin-top: 20px;
      li {
        display: flex;
        align-items: center;
        margin-top: 10px;
        p {
          white-space: nowrap;
        }
        /deep/.ant-input-number {
          width: 80px;
        }
        .delete {
          color: #3681f0;
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
.left_name_price {
  align-items: initial;
}

.top {
  margin: 24px 0;
}

// 系列购买
.series_box {
  margin-left: 20px;
  max-width: 800px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      display: inline-block;
      width: 140px;
      text-align: right;
      color: #333333;
    }
  }

  .series_flex {
    display: flex;
    align-items: center;
    .center {
      width: 80px;
      margin: 0 10px;
    }
    .time {
      font-size: 14px;
      margin-right: 10px;
    }
  }

  .all_input {
    width: 240px;
  }
  .right-box {
    display: flex;
    margin: 24px 0 0 100px;
  }
}
.picture {
  width: 120px;
}
.button {
  .btn {
    margin: 24px 14px;
  }
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
  /deep/ .ant-tabs-bar {
    margin: 0;
  }
}

.search-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .search-input {
    width: 36%;
  }
}
.table1 {
  border-collapse: collapse;
  width: 730px;
  box-shadow: 0px 0px 4px 1px #d6d4d4;
  .trtitle {
    width: 100%;
    height: 46px;
    background-color: #f6f6fc;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .td1 {
    width: 80px;
    height: 46px;

    text-align: center;
  }
  .td2 {
    height: 46px;
    width: 445px;
    text-align: center;
  }
  .td3 {
    height: 46px;
    width: 200px;
    text-align: center;
  }
  .lititle {
    width: 100%;
    border-bottom: #e7e7e7 solid 1px;
    font-size: 12px;
    .trd1 {
      width: 80px;
      height: 46px;
      border-right: #e7e7e7 1px solid;
      text-align: center;
    }
    .trd2 {
      height: 46px;
      width: 445px;
      text-align: center;
    }
    .trd3 {
      border-left: #e7e7e7 1px solid;
      height: 46px;
      width: 200px;
      text-align: center;
      a {
        color: #3681f0;
      }
    }
  }
}
</style>
